import produce from 'immer';
import React, {
  ReactElement,
  createContext,
  useReducer,
  useContext,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {suspend} from 'suspend-react';
import {getToken} from './AuthManager';
import {buildCategoriesChildrenFromFlatStructure} from './helpers';
import {CategoriesChildren, Category, unhandledCase} from './types';

type TreeCache = Record<number, CategoryCacheObject>;
type CategoryCacheObject = Omit<Category, 'children'> & {
  childrenIds: number[];
  parentId: number | null;
};
type ReducerState = {
  visibleIds: number[];
  selectedId: null | number;
};
type Actions =
  | {type: 'TOGGLE_SUBTREE'; id: number}
  | {type: 'SELECT_CATEGORY'; id: number}
  | {type: 'FILTER_TREE_BY_NAME'; name: string}
  | {type: 'RESET'};

export type TreeViewHandle = {
  reset: () => void;
};

const TreeViewContext = createContext<{
  state: ReducerState;
  dispatch: React.Dispatch<Actions>;
}>({state: {visibleIds: [], selectedId: null}, dispatch: () => {}});

function buildTreeCache(
  categoriesTree: CategoriesChildren,
  parentId: number | null,
): TreeCache {
  const treeCache: TreeCache = {};
  categoriesTree.forEach((category) => {
    const {children, ...categoryRest} = category;
    const childrenIds = children.map((categoryChild) => categoryChild.id);
    const childrenSubtree = buildTreeCache(children, categoryRest.id);
    const categoryLookup = {
      ...categoryRest,
      childrenIds,
      parentId: parentId,
    };
    treeCache[category.id] = categoryLookup;
    Object.entries(childrenSubtree).forEach(([id, subtreeChildCategory]) => {
      treeCache[Number(id)] = subtreeChildCategory;
    });
  });
  return treeCache;
}

function getRecursiveParentIds(
  cacheObj: CategoryCacheObject,
  cache: TreeCache,
): number[] {
  const parentIds: number[] = [];
  if (cacheObj.parentId) {
    parentIds.push(cacheObj.parentId);
    parentIds.push(...getRecursiveParentIds(cache[cacheObj.parentId], cache));
  }
  return parentIds;
}

function getRecursiveChildrenIds(
  cacheObj: CategoryCacheObject,
  cache: TreeCache,
): number[] {
  const childrenIds: number[] = [];
  for (const childId of cacheObj.childrenIds) {
    const childCacheObj = cache[childId];
    const nestedChildrenIds = getRecursiveChildrenIds(childCacheObj, cache);
    childrenIds.push(childId);
    childrenIds.push(...nestedChildrenIds);
  }
  return childrenIds;
}

function getCategoryIdsForParentId(
  categoriesCache: TreeCache,
  parentId: number | null,
): number[] {
  const ids: number[] = [];
  for (const [id, category] of Object.entries(categoriesCache)) {
    if (category.parentId === parentId) {
      ids.push(Number(id));
    }
  }
  return ids;
}

const categoriesFetchUUID = Symbol.for('categoriesFetchUUID');

function TreeViewRoot(
  props: {name: string},
  ref: React.Ref<TreeViewHandle>,
): ReactElement {
  const data = suspend(async () => {
    try {
      const res = await fetch('/api/categories', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return await res.json();
    } catch {
      return {};
    }
  }, [categoriesFetchUUID]);

  const {categories: flatCategories} = data;
  const categories = buildCategoriesChildrenFromFlatStructure(flatCategories);

  const cache = useRef<TreeCache>({});
  const initializeReducer = useCallback(
    (categories: CategoriesChildren): ReducerState => {
      cache.current = buildTreeCache(categories, null);
      return {
        visibleIds: getCategoryIdsForParentId(cache.current, null),
        selectedId: null,
      };
    },
    [],
  );
  const reducer = useCallback(
    (state: ReducerState, action: Actions): ReducerState => {
      const {type} = action;
      switch (type) {
        case 'TOGGLE_SUBTREE': {
          const idToToggle = action.id;
          const category = cache.current[idToToggle];
          const isTopLevelCategory = category.parentId === null;
          const {childrenIds} = category;
          const allChildrenIdsInVisibleIds = childrenIds.every((id) =>
            state.visibleIds.includes(id),
          );
          if (allChildrenIdsInVisibleIds) {
            // hide already visible subtree
            return produce(state, (draft) => {
              draft.visibleIds = draft.visibleIds.filter(
                (id) => !childrenIds.includes(id),
              );
            });
          }
          return produce(state, (draft) => {
            if (isTopLevelCategory) {
              draft.visibleIds = getCategoryIdsForParentId(cache.current, null);
            }
            draft.visibleIds.push(...childrenIds);
          });
        }
        case 'FILTER_TREE_BY_NAME': {
          const nameToFilter = action.name.toLowerCase();
          if (nameToFilter === '') {
            return produce(state, (draft) => {
              draft.visibleIds = getCategoryIdsForParentId(cache.current, null);
            });
          }
          const matches = Object.values(cache.current).filter((category) =>
            category.name.toLowerCase().includes(nameToFilter),
          );
          const parentIds = matches
            .map((match) => getRecursiveParentIds(match, cache.current))
            .flat();
          const childrenIds = matches
            .map((match) => getRecursiveChildrenIds(match, cache.current))
            .flat();
          return produce(state, (draft) => {
            draft.visibleIds = matches.map((e) => e.id);
            draft.visibleIds.push(...parentIds, ...childrenIds);
          });
        }
        case 'SELECT_CATEGORY': {
          return produce(state, (draft) => {
            draft.selectedId = action.id;
          });
        }
        case 'RESET': {
          return produce(state, (draft) => {
            draft.selectedId = null;
            draft.visibleIds = getCategoryIdsForParentId(cache.current, null);
          });
        }
      }
      return unhandledCase(type);
    },
    [],
  );
  const [state, dispatch] = useReducer(reducer, categories, initializeReducer);

  useImperativeHandle(
    ref,
    () => ({
      reset: (): void => {
        dispatch({type: 'RESET'});
      },
    }),
    [],
  );

  const selectedWithParents = state.selectedId
    ? [
        state.selectedId,
        ...getRecursiveParentIds(
          cache.current[state.selectedId],
          cache.current,
        ),
      ].reverse()
    : null;
  return (
    <TreeViewContext.Provider value={{state, dispatch}}>
      <div className="mb-5">
        <input
          type="text"
          placeholder="Filter categories"
          className="
                        w-full
                        rounded-sm
                        border
                        bordder-[#E9EDF4]
                        py-3
                        px-5
                        bg-[#FCFDFE]
                        text-base text-body-color
                        placeholder-[#ACB6BE]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-sky-400
                        "
          onChange={(event): void => {
            dispatch({type: 'FILTER_TREE_BY_NAME', name: event.target.value});
          }}
        />
      </div>
      <input
        type="hidden"
        name={props.name}
        value={String(selectedWithParents)}
      />
      <div className="mb-5">
        <input
          type="text"
          placeholder="Select a category"
          className="
                        w-full
                        rounded-sm
                        border
                        bordder-[#E9EDF4]
                        py-3
                        px-5
                        bg-[#FCFDFE]
                        text-base text-body-color
                        placeholder-[#ACB6BE]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-sky-400
                        cursor-not-allowed
                        "
          readOnly
          value={
            selectedWithParents
              ? selectedWithParents
                  .map((id) => cache.current[id].name)
                  .join(' > ')
              : ''
          }
        />
      </div>
      <TreeView categories={categories} />
    </TreeViewContext.Provider>
  );
}

function TreeView(props: {categories: CategoriesChildren}): ReactElement {
  const {
    state: {visibleIds},
    dispatch,
  } = useContext(TreeViewContext);
  if (visibleIds.length === 0) {
    return <div>No results</div>;
  }
  return (
    <ul className="list-disc">
      {props.categories.map((category) => {
        if (!visibleIds.includes(category.id)) {
          return null;
        }
        return (
          <li
            className="ml-6 marker:text-sky-400"
            key={category.id}
            onClick={(event): void => {
              event.stopPropagation();
              if (category.children.length > 0) {
                dispatch({type: 'TOGGLE_SUBTREE', id: category.id});
              } else {
                dispatch({type: 'SELECT_CATEGORY', id: category.id});
              }
            }}>
            <button className="cursor-pointer" type="button">
              {category.name}
              {category.children.length > 0 && (
                <span className="ml-1 text-sky-400 transform scale-[0.60] inline-block">
                  {/* &#9650; */}
                  &#9660;
                </span>
              )}
            </button>
            {category.children.length > 0 && (
              <TreeView categories={category.children} />
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default forwardRef(TreeViewRoot);
