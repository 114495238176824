import type {CategoriesChildren, Category} from '../types';
import {object, number, array, boolean, string} from 'yup';

type CategoryDbObject = {id: number; name: string; parentId: null | number};

export function buildCategoriesChildrenFromFlatStructure(
  categories: CategoryDbObject[],
): CategoriesChildren {
  const topLevelCategories = categories.filter(
    (category) => category.parentId === null,
  );
  return topLevelCategories.map((category) => {
    const {parentId, ...rest} = category;
    return {...rest, children: findAllChildren(category, categories)};
  });
}

function findAllChildren(
  category: CategoryDbObject,
  allCategories: CategoryDbObject[],
): Category[] {
  const idToSearch = category.id;
  const children = allCategories.filter((e) => e.parentId === idToSearch);
  const childrenWithoutParentId = children.map((child) => {
    const {parentId, ...rest} = child;
    return {
      ...rest,
      children: findAllChildren(child, allCategories),
    };
  });

  return childrenWithoutParentId;
}

export enum InputNames {
  CATEGORY = 'category',
  ADDITIONAL_ISSUE = 'additionalIssue',
  ESCALATION = 'escalation',
  REFUND = 'refund',
  OPERTOR_ID = 'operator_id',
  VISITOR_ID = 'visitor_id',
  COMMENT = 'comment',
}

export const formSchema = object({
  [InputNames.CATEGORY]: array()
    .of(number().required())
    .transform((_, originalCategory) => originalCategory.split(',').map(Number))
    .required(),
  [InputNames.ADDITIONAL_ISSUE]: boolean()
    .transform((_, e) => e === 'on')
    .default(false)
    .required(),
  [InputNames.ESCALATION]: boolean()
    .transform((_, e) => e === 'on')
    .default(false)
    .required(),
  [InputNames.REFUND]: boolean()
    .transform((_, e) => e === 'on')
    .default(false)
    .required(),
  [InputNames.VISITOR_ID]: string().required(),
  [InputNames.COMMENT]: string().optional(),
});
