import {ReactElement, useEffect, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

initializeApp(firebaseConfig);

const auth = getAuth();

function login(): void {
  signInWithPopup(auth, new GoogleAuthProvider())
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      const idToken = credential?.idToken;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

enum LoginState {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
  PENDING = 'PENDING',
}

interface Props {
  children: ReactElement;
}

let token = '';
export function getToken(): string {
  return token;
}

export default function AuthManager({children}: Props): ReactElement | null {
  const [loggedState, setLoggedState] = useState<LoginState>(
    LoginState.PENDING,
  );
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        token = uid;
        setLoggedState(LoginState.SIGNED_IN);
      } else {
        setLoggedState(LoginState.SIGNED_OUT);
      }
    });
  }, []);

  if (loggedState === LoginState.SIGNED_OUT) {
    return <button onClick={login}>Login</button>;
  }
  if (loggedState !== LoginState.SIGNED_IN) {
    return null;
  }
  return children;
}
