export type CategoriesChildren = Category[];

export type Category = {
  name: string;
  id: number;
  children: CategoriesChildren;
};

export function unhandledCase(x: never): never {
  throw new Error('Unhandled case');
}
