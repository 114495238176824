import {FormEvent, ReactElement, Suspense, useRef, useState} from 'react';
import Swal from 'sweetalert2';
import TreeView, {TreeViewHandle} from './TreeView';
import AuthManager, {getToken} from './AuthManager';
import {formSchema, InputNames} from './helpers';

function App(): ReactElement {
  const [visitorId, setVisitorId] = useState(() => {
    try {
      return new URLSearchParams(window.location.search).get('visitor_id');
    } catch (error) {
      //
    }
    return '';
  });

  const treeViewController = useRef<TreeViewHandle>(null);

  async function onSubmit(event: FormEvent<HTMLFormElement>): Promise<boolean> {
    event.preventDefault();
    const currentTarget = event.currentTarget;
    const formData = new FormData(currentTarget);
    const castedValues = await formSchema.cast(
      Object.fromEntries(formData.entries()),
    );
    try {
      // TODO validate selected category too
      await formSchema.validate(castedValues, {strict: true});
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Validation error',
        text: error instanceof Error ? error.message : 'Validation error',
      });
      return false;
    }
    try {
      const response = await fetch('/api/entry', {
        method: 'POST',
        body: JSON.stringify(castedValues),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      if (response.status !== 200) {
        throw new Error(responseData?.message);
      }
      Swal.fire({
        icon: 'success',
        title: 'Form submitted successfully!',
        text: 'You can close this tab safely now.',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      currentTarget.reset();
      treeViewController.current?.reset();
      setVisitorId(null);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error while submitting form',
        text: error instanceof Error ? error.message : 'Unknown error occurred',
      });
      console.log(error);
    }
    return true;
  }

  return (
    <AuthManager>
      <section className="py-20 lg:py-[120px]">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div
                className="
               max-w-[525px]
               mx-auto
               bg-white
               rounded-sm
               relative
               overflow-hidden
               py-16
               px-10
               sm:px-12
               md:px-[60px]
               ">
                <Suspense fallback="Loading...">
                  <form onSubmit={onSubmit}>
                    <div className="mb-5">
                      <p className="text-lg">
                        {visitorId ? (
                          <>
                            Filling form for visitorId:{' '}
                            <a
                              href={`https://tidio.com/panel/conversations/${visitorId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sky-400 hover:text-sky-500">
                              {visitorId}
                            </a>
                          </>
                        ) : (
                          'No visitorId provided'
                        )}
                      </p>
                      <input
                        className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-400 checked:border-sky-400 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        name={InputNames.VISITOR_ID}
                        type="hidden"
                        id={InputNames.VISITOR_ID}
                        value={visitorId || ''}
                      />
                    </div>
                    <div className="mb-5">
                      <TreeView
                        name={InputNames.CATEGORY}
                        ref={treeViewController}
                      />
                    </div>
                    <div className="mb-5">
                      <input
                        className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-400 checked:border-sky-400 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        name={InputNames.ADDITIONAL_ISSUE}
                        type="checkbox"
                        id={InputNames.ADDITIONAL_ISSUE}
                      />
                      <label
                        className="form-check-label inline-block"
                        htmlFor={InputNames.ADDITIONAL_ISSUE}>
                        Additional issue
                      </label>
                    </div>
                    <div className="mb-5">
                      <input
                        className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-400 checked:border-sky-400 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        name={InputNames.ESCALATION}
                        type="checkbox"
                        id={InputNames.ESCALATION}
                      />
                      <label
                        className="form-check-label inline-block"
                        htmlFor={InputNames.ESCALATION}>
                        Escalation
                      </label>
                    </div>
                    <div className="mb-5">
                      <input
                        className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-sky-400 checked:border-sky-400 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        name={InputNames.REFUND}
                        type="checkbox"
                        id={InputNames.REFUND}
                      />
                      <label
                        className="form-check-label inline-block"
                        htmlFor={InputNames.REFUND}>
                        Refund
                      </label>
                    </div>
                    <div className="mb-5">
                      <textarea
                        className="w-full
                        rounded-sm
                        border
                        bordder-[#E9EDF4]
                        py-3
                        px-5
                        bg-[#FCFDFE]
                        text-base text-body-color
                        placeholder-[#ACB6BE]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-sky-400"
                        placeholder="Comment"
                        name={InputNames.COMMENT}
                        id={InputNames.COMMENT}
                        maxLength={1000}
                      />
                    </div>
                    <div className="mb-5">
                      <button
                        type="submit"
                        className="
                        w-full
                        rounded-sm
                        border
                        border-sky-400
                        py-3
                        px-5
                        bg-sky-400
                        text-base text-white
                        cursor-pointer
                        hover:bg-opacity-90
                        transition
                        ">
                        Submit
                      </button>
                    </div>
                  </form>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AuthManager>
  );
}

export default App;
